import { createStore } from 'vuex'
import userMenuData from './userMenuData'
export default createStore({
  state: {
    // upUrl:'http://172.25.2.3:80',
    upUrl:window.Glod.BaseUrl,
    upUrl2:'http://172.25.2.1:80'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userMenuData
  }
})
