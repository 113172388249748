 import instance from "@/request/request";


 // 登录
 export const login = (params) => instance.post("/api/b/login", params);

 // 获取用户可以访问的路由的api
 export const getMenu = () => instance.post("/api/b/user/menu");
 export const getMenuC = () => instance.get("/api/d/tertiary/menu");

 //客户端子账号列表
 export const childList = (params) => instance.post("/api/b/child/list",params);

 //增改子账户模块
 export const childEdit = (params) => instance.post("/api/b/child/edit",params);

 //更新状态 停启用
 export const changeStatus = (params) => instance.post("/api/b/child/change_status",params);

 //删除子账号
 export const childDel = (params) => instance.post("/api/b/child/del",params);

 //获取注册链接
 export const registerUrl = (params) => instance.post("/api/b/child/register_url",params);

 //角色列表
 export const roleList = (params) => instance.post("/api/b/child/role_list",params);

 //角色对应用户查询下
 export const roleUser = (params) => instance.post("/api/b/child/role_user",params);

 //增改角色信息
 export const roleEdit = (params) => instance.post("/api/b/child/role_edit",params);

 //删除角色信息
 export const roleDel = (params) => instance.post("/api/b/child/role_del",params);

 //转移用户
 export const userTransfer = (params) => instance.post("/api/b/child/role_user_transfer",params);

 //权限菜单列表
 export const roleMenus = (params) => instance.post("/api/b/child/role_menus",params);

 //提交角色和菜单的关联
 export const roleMenusRelation = (params) => instance.post("/api/b/child/role_menus_relation",params);

 //线索池列表
 export const cluePoolList = (params) => instance.post("/api/b/crm/clue_pool_list",params);

 //获客来源列表
 export const comeSource = () => instance.get("/api/sys/come_source");

 //获取枚举值
 export const businessEnums = () => instance.get("/api/tiktok/enums");

 //增改线索池
 export const cluePoolEdit = (params) => instance.post("/api/b/crm/clue_pool_edit",params);

 export const getPersonalClueList = (params) => instance.post("/api/b/crm/user_clue_pool_list",params);

 //批量删除线索
 export const cluePoolDel = (params) => instance.post("/api/b/crm/clue_pool_del",params);

 //领取线索
 export const cluePoolReceive = (params) => instance.post("/api/b/crm/clue_pool_batch_receive",params);

//线索池上传文件
 export const cluePoolImport = (params) => instance.post("/api/b/crm/clue_pool_import",params);

//跟进历史

 export const followList = (params) => instance.post("/api/b/crm/clue_pool_follow_list",params);

 //创建跟进记录
 export const followCreate = (params) => instance.post("/api/b/crm/clue_pool_follow_create",params);

 //修改跟进记录
 export const followEdit = (params) => instance.post("/api/b/crm/clue_pool_follow_edit",params);

//批量分配线索

 export const batchAllocation = (params) => instance.post("/api/b/crm/clue_pool_batch_allocation",params);


//个人线索池列表

 export const userPoolList = (params) => instance.post("/api/b/crm/user_clue_pool_list",params);

//增改个人线索池

 export const userPoolEdit = (params) => instance.post("/api/b/crm/user_clue_pool_edit",params);
//个人线索批量分配
 export const userPoolAllocation = (params) => instance.post("/api/b/crm/user_clue_pool_allocation",params);
//个人线索归还线索
 export const userPoolBack = (params) => instance.post("/api/b/crm/user_clue_pool_back",params);

 //删除个人线索池线索数据
 export const userPoolDel = (params) => instance.post("/api/b/crm/user_clue_pool_del",params);







 //客户列表
 export const customerList = (params) => instance.post("/api/b/crm/customer_manage_list",params);

 //分配客户
 export const customerAllocation = (params) => instance.post("/api/b/crm/customer_manage_allocation",params);

 //销售、运营人员获取
 export const userList = () => instance.get("/api/b/crm/user_list");

 //编辑客户信息
 export const customerEdit = (params) => instance.post("/api/b/crm/customer_manage_edit",params);

 //客户充值
 export const customerRecharge = (params) => instance.post("/api/b/crm/customer_manage_recharge",params);

 //客户退款
 export const customerRefund = (params) => instance.post("/api/b/crm/customer_manage_refund",params);

 //授信额度
 export const customerChangeCredit = (params) => instance.post("/api/b/crm/customer_manage_change_credit",params);

 //获取客户交易记录
 export const customerRecordList = (params) => instance.post("/api/b/crm/customer_transaction_record_list",params);




 //三代账户列表
 export const tertiaryList = (params) => instance.post("/api/b/tertiary/manage_list",params);

 //三代关联客户列表
 export const relevanceList = (params) => instance.post("/api/b/account/customer_list",params);
//创建三代客户
 export const relevanceAdd = (params) => instance.post("/api/b/tertiary/manage_create",params);

 //修改三代客户信息
 export const relevanceEdit = (params) => instance.post("/api/b/tertiary/manage_edit",params);

 //三代客户充值
 export const relevanceRecharge = (params) => instance.post("/api/b/tertiary/manage_recharge",params);

 //退款
 export const relevanceRefund = (params) => instance.post("/api/b/tertiary/manage_refund",params);

 //启用
 export const manageStart = (params) => instance.post("/api/b/tertiary/manage_start",params);

 //启用
 export const manageStop = (params) => instance.post("/api/b/tertiary/manage_stop",params);
//停启用
 export const manageStatus = (params) => instance.post("/api/b/tertiary/manage_status",params);

 //三代账户授信额度
 export const manageCreditChange = (params) => instance.post("/api/b/tertiary/manage_credit_change",params);

 //三代分配负责人
 export const manageAllocation = (params) => instance.post("/api/b/tertiary/manage_allocation",params);

 //权限组列表
 export const roleGroupList = (params) => instance.post("/api/b/child/role_group_list",params);

 //增改角色组信息
 export const roleGroupEdit = (params) => instance.post("/api/b/child/role_group_edit",params);

 //删除组
 export const roleGroupDel = (params) => instance.post("/api/b/child/role_group_del",params);

 //获取权限组用户信息
 export const groupUser = (params) => instance.post("/api/b/child/group_user",params);

 //角色组添加用户
 export const groupUserAdd = (params) => instance.post("/api/b/child/role_group_user_add",params);

 //角色组删除用户
 export const groupUserDel = (params) => instance.post("/api/b/child/role_group_user_del",params);

 //广告账户列表
 export const adList = (params) => instance.post("/api/b/account/list",params);

 //开户主体下拉列表数据
 export const subjectList = (params) => instance.post("/api/b/account/adever_subject_select",params);

 //下拉用的客户列表
 export const customerSelect = (params) => instance.post("/api/b/crm/customer_select",params);
 export const customerPoolSelect = (params) => instance.post("/api/b/crm/customer_pool_select",params);

 //账户充值
 export const rechargeMoney = (params) => instance.post("/api/b/account/batch_recharge_money",params);

 //账户转减款
 export const transferMoney = (params) => instance.post("/api/b/account/transit_money",params);

 //下拉使用搜索广告账户信息
 export const accountListSelect = (params) => instance.post("/api/b/account/account_list_select",params);

 //账户清零
 export const accountClear = (params) => instance.post("/api/b/account/clear",params);

 //绑定/解绑BC
 export const bindBC = (params) => instance.post("/api/b/account/bind_bc",params);
 //广告账户批量绑定客户
 export const bindCustomers = (params) => instance.post("/api/b/account/batch_bind_customer",params);
 //获取投放地区列表
 export const dropAreas = () => instance.get("/api/sys/drop_area");

 //推广内容列表
 export const promotion = () => instance.get("/api/sys/promotion");

 //标签设置
 export const editTitle = (params) => instance.post("/api/b/account/edit_title",params);
 //批量改备注
 export const editRemarks = (params) => instance.post("/api/b/account/edit_remark",params);

 //广告账户操作记录
 export const transactionList = (params) => instance.post("/api/b/account/transaction_list",params);

 //账户信息接口
 export const accountInfo = (params) => instance.post("/api/b/finance/my_account",params);

 //汇率接口
 // export const getRate = () => instance.get("/api/rate/get");
 export const getRate = () => instance.get("/api/sys/rate_get");
 //二代充值
 export const financeRecharge = (params) => instance.post("/api/b/finance/recharge",params);

 //获取派安盈绑定链接
 export const getBindUrl = (params) => instance.post("/api/b/finance/bind_payoneer_url",params);

 //绑定派安盈
 export const bindPayoneer = (params) => instance.post("/api/b/finance/bind_payoneer", params);

 //获取派安盈钱包信息
 export const getPayoneerBalance = () => instance.post("/api/b/finance/get_payoneer_balance", );

 //获取用户信息
 export const getUserInfo = () => instance.post("/api/b/user/info", );

 //钱包记录
 export const getWalletRecord = (params) => instance.post("/api/b/finance/recharge_list",params);
 //取消订单
 export const repealOrder = (params) => instance.post("/api/b/finance/recharge_repeal",params);

 //二代账户授信额度变更记录
 export const creditOrderList = (params) => instance.post("/api/b/finance/credit_order_list",params);

 //按钮权限数据
 export const roleMenuOptions = () => instance.get("/api/b/child/role_menu_options", );

 //账户管理-分配负责人接口
 export const leaderUpdate = (params) => instance.post("/api/b/account/leader_update",params);

 //子账号-重置MFA
 export const resetMfa = (params) => instance.post("/api/b/child/reset_mfa",params);

 //子账号-交接
 export const handover = (params) => instance.post("/api/b/child/handover",params);

 //客户交易记录审核
 export const tradeRecordAudit = (params) => instance.post("/api/b/crm/customer_transaction_record_audit",params);

 //广告账户充值交易审核
 export const adAccountRechargeAudit = (params) => instance.post("/api/b/account/transaction_audit",params);

 //三代客户端-我的账户
 export const financeMyAccount = () => instance.post("/api/d/tertiary/finance_my_account", );

 //三代客户端-我的账户-交易记录
 export const financeMyAccountRecord = (params) => instance.post("/api/d/tertiary/finance_recharge_list",params);

 //三代客户端-广告账户列表
 export const adAccountList = (params) => instance.post("/api/d/tertiary/account_list",params);

 //三代客户端-广告账户-打标签
 export const adAccountTag = (params) => instance.post("/api/d/tertiary/account_title",params);

 //三代客户端-广告账户-充值
 export const adAccountRecharge = (params) => instance.post("/api/d/tertiary/account_batch_recharge",params);

 //三代广告账户下拉
 export const adAccountSelect = () => instance.get("/api/d/tertiary/account_select", );

 //三代广告账户-账户转减款
 export const adAccountTransfer = (params) => instance.post("/api/d/tertiary/account_transition_money",params);

 //三代广告账户-账户清零
 export const adAccountClear = (params) => instance.post("/api/d/tertiary/account_clear",params);

 //三代广告账户-解绑BC
 export const adAccountUnbind = (params) => instance.post("/api/d/tertiary/account_bind_bc",params);

 //三代客户端-广告账户操作记录
 export const adAccountRecord = (params) => instance.post("/api/d/tertiary/account_deal_list",params);

 //三代客户端-广告账户记录-撤销
 export const adAccountRecordRevoke = (params) => instance.post("/api/d/tertiary/account_deal_repeal",params);

 //获取线上支付列表
 export const getPayList = () => instance.get("/api/b/finance/recharge_type", );

 //个人中心信息数据接口
 export const getPersonalInfo = () => instance.post("/api/b/system_setting/personal_info", );

 //账户信息接口
 export const getAccountInfo = () => instance.post("/api/b/finance/my_account", );

 //更改名称
 export const changeName = (params) => instance.post("/api/b/account/edit_name",params);

