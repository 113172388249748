<template>
  <div id="app">
    <a-config-provider
      :locale="locale"
      :theme="{
        token: {
          // colorPrimary: '#3401FF',
          colorPrimary: '#1677ff',
          // colorPrimary: '#2F54EB',
          borderRadius: 2
        },
      }"
    >
      <router-view></router-view>
    </a-config-provider>

  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>



<style scoped>

html,body,#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #F7F7F7 !important;
  /*background-color: #F2F7FF !important;*/
}
</style>
