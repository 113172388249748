import { message } from 'ant-design-vue';
import axios from 'axios'
import { useAxios } from '@vueuse/integrations/useAxios'
import router from "@/router";
const instance = axios.create({
    baseURL: window.Glod.BaseUrl,
    // baseURL: 'http://172.25.2.3:80',
    timeout: 100000
})
instance.interceptors.request.use(config => {
    const token = localStorage.getItem("edb-authorization-token")
    if (token && !config.url.endsWith("/login") && !config.url.endsWith("/captchaImage")) {
        config.headers["Authorization"] = token
        // "Bearer " +
    }
    return config
}, err => {
    return Promise.reject(err)
});
instance.interceptors.response.use(res => {

    let res_data = res.data
    // 所以要让res_data到组件中去处理，不能再这里拦截死，所以要加res_data.code &&
    if (res_data.code && res_data.code != 0) {
        message.error(res_data.msg || '网络请求错误');
        // 只要不是0，都会走这里
        if ( res_data.code == 40003|| res_data.code == 40000 ) {
            // 401一般表示token过期或者没有带
            localStorage.removeItem("edb-authorization-token");
            router.push("/login")
        }
        // 这里return不是为了结束函数，实际上是把return值传到组件中的res
        return Promise.reject(res_data)
    }
    return res_data
}, err => {
    return Promise.reject(err)
});

// 简单包装一层，让参数更直观
export function useRequest(config, useAxiosOptions = { immediate: true }) {
  return useAxios(
    config.url,
    config,
    instance,
    useAxiosOptions
  )
}

export default instance
