// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
// //antd 组件
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/reset.css';
// import './assets/css/main.scss'
// import dragVerify from 'vue-drag-verify2'
// //antd 图标
// import * as antIcons from '@ant-design/icons-vue'
// //富文本
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css';
// //pinia状态存储
// import { createPinia } from 'pinia';
// const pinia = createPinia();
// const app = createApp(App);
// // 注册组件
// Object.keys(antIcons).forEach(key => {
//     app.component(key, antIcons[key])
// })
// // 添加到全局
// app.config.globalProperties.$antIcons = antIcons
// app.use(store).use(router).use(Antd).use(dragVerify).use(pinia).component('QuillEditor', QuillEditor).mount('#app')
//
//
//
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// antd 组件
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
// import './assets/css/main.scss'
// 字体包
import  './assets/css/font.css'
import dragVerify from 'vue-drag-verify2'
// antd 图标
import * as antIcons from '@ant-design/icons-vue'
// 富文本
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// pinia 状态存储
import { createPinia } from 'pinia';
// swiper 样式
import 'swiper/dist/css/swiper.min.css'
import '@/styles/index.less'


//复制插件
import VueClipBoard from 'vue-clipboard2'

// 创建 Pinia 实例
const pinia = createPinia();

const app = createApp(App);

// 先安装 Pinia
app.use(pinia);

// 注册组件
Object.keys(antIcons).forEach(key => {
    app.component(key, antIcons[key])
})

// 添加到全局
app.config.globalProperties.$antIcons = antIcons

// 然后安装其他插件和组件
app.use(store).use(router).use(Antd).use(dragVerify).use(VueClipBoard).component('QuillEditor', QuillEditor).mount('#app')
