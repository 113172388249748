import {createRouter, createWebHistory} from 'vue-router'
import {message} from 'ant-design-vue'
// import HomeView from '../views/HomeView.vue'
// import Vue from 'vue'
// import VueRouter from 'vue-router'
// Vue.use(VueRouter)
import store from "@/store"
import {getMenu, getMenuC} from "@/request/api"

const platForm = localStorage.getItem("platform")
const demoPage = {
  path: '/demo',
  name: 'demo',
  component: () => import(/* webpackChunkName: "demo" */ '../views/demo/Table')
}

// function listToTree(list) {
//   const tree = []
//   const map = {}
//   list.forEach((item) => {
//     map[item.ID] = item
//   })
//   list.forEach((item) => {
//     const parent = map[item.pid]
//     if (parent) {
//       ;(parent.children || (parent.children = [])).push(item)
//     } else {
//       tree.push(item)
//     }
//   })
//   return tree
// }



const routes = [
  {
    path: '/',
    name: 'mainlayout',
    redirect: "/home/index",
    meta: {
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "mainlayout" */ '../views/layout/MainLayout.vue'),
    children: []
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/login/register')
  }
]
const routesThree = [
  {
    path: '/',
    name: '三代客户端',
    redirect: "/third-generation-client/myAccount",
    component: () => import(/* webpackChunkName: "mainlayout" */ '../views/layout/MainLayout.vue'),
    children: [
      {
        path: "/third-generation-client/myAccount",
        meta: {name: "我的账户"},
        component: () => import('../views/third-generation-client/myAccount.vue')
      },
      {
        path: "/third-generation-client/advertising-account-management",
        meta: {name: "广告账户"},
        component: () => import('../views/third-generation-client/advertising-account-management.vue')
      },
      {
        path: "/third-generation-client/advertising-account-transaction-records",
        meta: {name: "广告账户交易记录"},
        component: () => import('../views/third-generation-client/advertising-account-transaction-records.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/login/register')
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  // 【重点】：只有next()是放行， 里面传了路径就变成跳转到，跳转到就会重新执行守卫的代码(这个函数)
  const token = localStorage.getItem("edb-authorization-token")
  // 管理系统常见的两个经典的逻辑：
  // 1、如果用户访问登录页面， 但是token已经存在， 跳转到首页
  if (to.path === "/login" && token) {
    next("/");  // 跳转到
    return
  }
  if (!token && to.path === "/register") {
    next()
    return
  }
  // 2、如果用户访问不是登录页面，但是没有token，跳转到登录页面
  if (to.path !== "/login" && !token) {
    next("/login")
    return
  }
  // token &&store.state.userMenuData.menuData.length==0
  if (token && store.state.userMenuData.menuData.length == 0) {
    let platform = localStorage.getItem("platform")
    // 获取用户的菜单数据
    let GetUserRoutersApiRes={
      data:[]
    }
    let originalData
    let newData=[]
    if (platform == 1) {
      // GetUserRoutersApiRes = await getMenu();
      originalData = await getMenu();
      originalData.data.forEach(item=>{
        if (item.pid===0) {
          newData.push(item)
        }
      })

      newData.forEach(item=>{
        item.children=[]
      })
      console.log('newData0000',newData)
      originalData.data.forEach(item=>{
        newData.forEach(iitem=>{
          if (item.pid===iitem.ID) {
            iitem.children.push(item)
          }
        })
      })
      console.log('newDa11111',newData)
      // originalData.data.forEach(item=>{
      //   newData.forEach(item1=>{
      //     if (!Array.isArray(item1.children)) {
      //       item1.children = []
      //     }else  {
      //       if (item.pid===item1.ID) {
      //           item1.children.push(item)
      //       }
      //     }
      //
      //   })
      // })
      newData.forEach(iitem=>{
        GetUserRoutersApiRes.data.push(iitem)
      })
      console.log('GetUserRoutersApiRes',GetUserRoutersApiRes)
    } else if (platform == 3) {
      GetUserRoutersApiRes = await getMenuC();
    } else {
      next("/login")
      return
    }
    if (!GetUserRoutersApiRes || !GetUserRoutersApiRes.data.length) {
      message.error('暂无权限，请联系管理员！')
      localStorage.clear()
      next("/login")
      return
    }
    let newUserMenuData = []
    let ret = GetUserRoutersApiRes.data.map(item => {
      if (item.children) {
        return {
          title: item.name,
          path: "/" + item.url,
          key: item.name,
          icon: item.icon,
          children: item.children.map(sitem => {
            return {
              openKey: item.name,
              title: sitem.name,
              key: sitem.name,
              path: "/" + item.url + "/" + sitem.url
            }
          })
        }
      } else {
        return {
          title: item.name,
          key: item.name,
          path: "/" + item.url,
          icon: item.icon,
          // icon: item.meta.icon,
        }
      }

    })
    newUserMenuData = [...ret];
    console.log('newUserMenuData',newUserMenuData)
    store.commit("userMenuData/changeMenuData", newUserMenuData)
    //动态路由注册
    let newChildrenRoutes = [];
    let home = [];
    home.push(demoPage)
    let retROuter = [];
    GetUserRoutersApiRes.data.forEach(item => {
      // if (item.children.length != 0 ) {
      if (item.children.length!=0 ) {
        item.children.forEach(sitem => {
          retROuter.push({
            path: '/' + item.url + '/' + sitem.url,
            name: sitem.url,
            key: '/' + item.url + '/' + sitem.url,

            component: () => import(`../views/${item.url}/${sitem.url}.vue`),
            meta: {
              openKey: item.name,
              titles: item.name + '/' + sitem.name,
              name: sitem.name,
              keepAlive: false
            }
          })
        })
      } else {
        home.push(
          {
            path: "/" + item.url,
            name: item.url,
            key: item.name,

            meta: {
              titles: item.name,
              name: item.name,
              keepAlive: false
            },
            component: () => import(`../views/${item.url}.vue`)
          }
        );
      }
    });
    newChildrenRoutes = [...home, ...retROuter];
    if (platform == 1) {
      newChildrenRoutes.forEach(item => {
        console.log('sdasdasd',item)
        router.addRoute("mainlayout", item);
      });
    } else if (platform == 3) {

      router.addRoute("mainlayout",
        {
          path: "/third-generation-client/myAccount",
          meta: {
            name: "我的账户",
            openKey:'三代客户端',
          },
          component: () => import('../views/third-generation-client/myAccount.vue')
        },);
      router.addRoute("mainlayout",
        {
          path: "/third-generation-client/advertising-account-management",
          meta: {
            name: "广告账户",
            openKey:'三代客户端',
          },
          component: () => import('../views/third-generation-client/advertising-account-management.vue')
        },
      );
      router.addRoute("mainlayout",
        {
          path: "/third-generation-client/advertising-account-transaction-records",
          meta: {
            name: "广告账户交易记录",
            openKey:'三代客户端',
          },
          component: () => import('../views/third-generation-client/advertising-account-transaction-records.vue')
        },
      );
    }

    next(to.fullPath);
    return
  }

  next()  // 放行
})


export default router
